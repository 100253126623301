<template>
  <div>
    <eden-table-actions :title="title" @search="setQuery">
      <template slot="actions">
        <el-button
          type="primary"
          @click="
            item.action = 'add';
            item.visibility = true;
          "
        >
          Add New Item
        </el-button>
      </template>
    </eden-table-actions>
    <template v-if="loading">Loading...</template>
    <template v-else>
      <el-table :data="items">
        <el-table-column>
          <template slot="header">
            <span>Name</span>
          </template>
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>Added on</span>
          </template>
          <template slot-scope="scope">
            <span>{{
              formatDateAndTime(scope.row.created_at, "dddd ddd, m, y")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>Updated on</span>
          </template>
          <template slot-scope="scope">
            <span>{{
              formatDateAndTime(scope.row.updated_at, "dddd ddd, m, y")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column width="90">
          <template slot-scope="scope">
            <el-dropdown @command="command">
              <span class="el-dropdown-link more">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :icon="'eden-icon-edit'"
                  :command="{
                    index: scope.$index,
                    action: 'edit',
                  }"
                  >Edit item</el-dropdown-item
                >
                <el-dropdown-item
                  :icon="'eden-icon-delete'"
                  :command="{
                    index: scope.$index,
                    action: 'delete',
                  }"
                  >Delete item</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <laundry-items-form
      :show.sync="item.visibility"
      :action="item.action"
      :item="item.data"
      @success="getItems"
    />
    <laundry-item-delete
      :show.sync="showLaundryItemDelete"
      :item="item.data"
      @success="updateItems"
    />
  </div>
</template>

<script>
import laundry from "@/requests/services/laundry";
import LaundryItemsForm from "@/components/Services/Laundry/LaundryItems/LaundryItemForm";
import LaundryItemDelete from "@/components/Services/Laundry/LaundryItems/LaundryItemDelete";

export default {
  name: "LaundryItems",
  components: { LaundryItemDelete, LaundryItemsForm },
  data() {
    return {
      loading: false,
      searchQuery: "",
      page: 1,
      pageData: [],
      actions: [
        {
          access: ["superadmin", "admin", "gardener_pro", "operations"],
          icon: "eden-icon-add-circle",
          label: "Edit item",
          name: "edit",
          component: "laundry-item-form",
        },
        {
          access: ["superadmin", "admin", "gardener_pro"],
          icon: "eden-icon-delete",
          label: "Delete item",
          name: "delete",
          component: "laundry-item-delete",
        },
      ],
      item: {
        action: "add",
        visibility: false,
        data: {},
      },
      showLaundryItemDelete: false,
    };
  },
  computed: {
    items() {
      const query = this.searchQuery?.toLowerCase();

      if (query) {
        return this.pageData.filter((item) => {
          return item.name.toLowerCase().includes(query);
        });
      }
      return this.pageData;
    },
    title() {
      return `${this.loading ? 0 : this.pageData.length} Items`;
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      laundry
        .listItems()
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.pageData = this.sortArray({
              data: data.data,
              property: "name",
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setQuery(query) {
      this.searchQuery = query;
    },
    command(command) {
      const { action, index } = command;
      this.item.data = {
        index,
        ...this.pageData[index],
      };
      if (action === "edit") {
        this.item.action = "edit";
        this.item.visibility = true;
      } else {
        this.showLaundryItemDelete = true;
      }
    },
    updateItems() {
      const { index } = this.item.data;
      this.pageData.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
