<template>
  <el-dialog :title="title" :visible.sync="setShow" width="30%" append-to-body>
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Item name"
            prop="name"
            :rules="validateField('Item name')"
          >
            <el-input type="text" v-model="form.name" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        v-if="action === 'add'"
        type="primary"
        @click="add"
        :loading="adding"
        :disabled="!form.name"
        >Add</el-button
      >
      <el-button v-else type="primary" @click="update" :loading="updating"
        >Save changes</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import laundry from "@/requests/services/laundry";

export default {
  name: "LaundryItemsForm",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "add",
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        name: "",
      },
      adding: false,
      updating: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return `${this.action === "add" ? "Add New" : "Edit"} Laundry Item`;
    },
  },
  watch: {
    show() {
      if (this.show && this.action === "edit") {
        this.form.name = this.item.name;
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.form.name = "";
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        let payload = {
          name: this.form.name.split(","),
        };

        laundry
          .addItem(payload)
          .then((response) => {
            if (response.data.status) {
              this.adding = false;
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
            } else {
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        laundry
          .updateItem(this.item.id, this.form)
          .then((response) => {
            if (response.data.status) {
              this.updating = false;
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
            } else {
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
