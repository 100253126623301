<template>
  <div>
    <eden-confirm-dialog
      title="Delete Item"
      button-type="danger"
      button-text="Delete"
      :show.sync="setShow"
      @confirm="deleteItem"
    >
      <p>
        Are you sure you want to delete this item, <br />
        <span class="text-grey-primary text-bold">{{ item.name }}</span>
        ?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import laundry from "@/requests/services/laundry";

export default {
  name: "LaundryItemDelete",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    gardeners() {
      return this.$store.getters.gardeners_list;
    },
  },
  methods: {
    deleteItem() {
      this.deleting = true;
      laundry
        .deleteItem(this.item.id)
        .then((response) => {
          if (response.data.status) {
            this.$emit("success");
            this.$message.success(response.data.message);
            this.setShow = false;
          }
        })
        .catch((error) => {
          this.deleting = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>
