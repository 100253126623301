import axios from "axios";

export default {
  listItems() {
    return axios.get("laundry_tally_labels");
  },

  addItem(payload) {
    return axios.post("laundry_tally_labels", payload);
  },

  updateItem(id, payload) {
    return axios.put(`laundry_tally_labels/${id}`, payload);
  },

  deleteItem(id) {
    return axios.delete(`laundry_tally_labels/${id}`);
  },
};
