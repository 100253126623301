var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.setQuery}},[_c('template',{slot:"actions"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.item.action = 'add';
          _vm.item.visibility = true;}}},[_vm._v(" Add New Item ")])],1)],2),(_vm.loading)?[_vm._v("Loading...")]:[_c('el-table',{attrs:{"data":_vm.items}},[_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.name))])]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Name")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.formatDateAndTime(scope.row.created_at, "dddd ddd, m, y")))])]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Added on")])])],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.formatDateAndTime(scope.row.updated_at, "dddd ddd, m, y")))])]}}])},[_c('template',{slot:"header"},[_c('span',[_vm._v("Updated on")])])],2),_c('el-table-column',{attrs:{"width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',{on:{"command":_vm.command}},[_c('span',{staticClass:"el-dropdown-link more"},[_c('i',{staticClass:"eden-icon-more"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":'eden-icon-edit',"command":{
                  index: scope.$index,
                  action: 'edit',
                }}},[_vm._v("Edit item")]),_c('el-dropdown-item',{attrs:{"icon":'eden-icon-delete',"command":{
                  index: scope.$index,
                  action: 'delete',
                }}},[_vm._v("Delete item")])],1)],1)]}}])})],1)],_c('laundry-items-form',{attrs:{"show":_vm.item.visibility,"action":_vm.item.action,"item":_vm.item.data},on:{"update:show":function($event){return _vm.$set(_vm.item, "visibility", $event)},"success":_vm.getItems}}),_c('laundry-item-delete',{attrs:{"show":_vm.showLaundryItemDelete,"item":_vm.item.data},on:{"update:show":function($event){_vm.showLaundryItemDelete=$event},"success":_vm.updateItems}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }